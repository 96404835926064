<template>
  <v-container fluid class="mb-16">
    <v-card height="100%" elevation="0">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #4caf5d">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Today's Order
                  <v-spacer></v-spacer>
                  <v-list text style="background: #4caf5d; color: white">{{
                    today_order
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #0083bf">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Total Customers
                  <v-spacer></v-spacer>
                  <v-list text style="background: #0083bf; color: white">{{
                    total_customer
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #00bfa5">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Total Seller
                  <v-spacer></v-spacer>
                  <v-list text style="background: #00bfa5; color: white">{{
                    total_seller
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #9fa8da">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Total Staff
                  <v-spacer></v-spacer>
                  <v-list text style="background: #9fa8da; color: white">{{
                    total_staff
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="ml-7" style="width: 96%">
            <v-col cols="3">
              <v-card
                style="background: #f5f5f5; overflow: auto; min-height: 90%"
                class="pb-1"
              >
                <v-card-title class="pt-5 pb-0 pl-14">
                  Support Ticket
                  <v-spacer></v-spacer>
                </v-card-title>

                <v-card-actions class="ml-2 mb-2">
                  <v-list three-line color="#f5f5f5" width="100%">
                    <template v-for="(item, index) in tickets">
                      <v-divider
                        v-if="item.divider"
                        :key="index"
                        :inset="item.inset"
                      ></v-divider>

                      <v-list-item
                        v-else
                        :key="item.sender_name"
                        @click="openchat(item)"
                        round
                      >
                        <v-avatar v-if="item.sender_name" color="indigo">
                          <span class="white--text">{{
                            item.sender_name.slice(0, 2)
                          }}</span>
                        </v-avatar>
                        <v-avatar v-else color="indigo">
                          <span class="white--text">Un</span>
                        </v-avatar>

                        <v-list-item-content class="pl-4 mt-3">
                          <v-list-item-title v-if="item.sender_name">
                            User Name: {{ item.sender_name }}
                          </v-list-item-title>
                          <v-list-item-title v-else>Unknown</v-list-item-title>
                          <v-list-item-subtitle>
                            User ID: {{ item.user_id }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card-actions>

                <v-btn
                  style="position: absolute; right: 0; bottom: 0px"
                  to="/dashboard/pages/supports"
                  color="primary"
                  elevation="0"
                  bottom
                  class="mx-0 mb-0"
                  block
                >
                  See More</v-btn
                >
                <!-- <v-card-actions style="position:absolute ; right:0;">
                  <v-spacer></v-spacer>
                  <v-btn to="/dashboard/pages/supports" color="primary" elevation="0" bottom> See More</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>

            <v-col cols="9">
              <v-card style="background: #f5f5f5">
                <v-row style="min-height: 100%">
                  <v-col cols="6">
                    <v-card-title class="pl-6">
                      Delivery Statistics
                    </v-card-title>
                    <v-card style="background: white" class="ml-6">
                      <v-card-actions class="ml-2">
                        <div id="chart">
                          <!-- width="380" -->
                          <!-- <apexchart
                            type="pie"
                           
                            :series="series"
                            :options="chartOptions"
                          ></apexchart> -->
                          <apexchart
                            type="pie"
                            width="380"
                            :options="chartOptions"
                            :series="seriesdemo"
                          ></apexchart>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title> Order Statistics </v-card-title>
                    <v-card
                      style="background: white"
                      class="mr-6 mb-0"
                      height="78%"
                    >
                      <v-card-actions class="ml-2">
                        <OrderChart />
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="ml-7" style="width: 96%">
            <v-col cols="6">
              <template>
                <v-card>
                  <v-data-table
                    :headers="WarehouseHeaders"
                    :items="warehouses"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    no-data-text="No Warehouse available"
                    item-key="id"
                    class="elevation-1"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                        <p class="mb-0">Warehouse</p>
                        <v-spacer></v-spacer>

                        <v-divider class="mx-4" inset vertical></v-divider>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="viewDetailsWarehouse(item)"
                        >Details</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-col>
            <v-col cols="6">
              <template>
                <v-card>
                  <v-data-table
                    :headers="shopHeaders"
                    :items="shops"
                    no-data-text="No Warehouse available"
                    item-key="id"
                    class="elevation-1"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                        <p class="mb-0">Shop</p>
                        <v-spacer></v-spacer>

                        <v-divider class="mx-4" inset vertical></v-divider>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="viewDetails(item)"
                        >Details</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-card-text></v-row
      ></v-card
    ></v-container
  >
</template>


<script>
export default {
  components: {
    OrderChart: () => import("./components/core/OrderChart"),
  },

  data: () => ({
    expanded: [],
    dialog: false,
    check: false,
    loading: true,
    selection: 1,
    warehouses: [],
    search: "",
    editdialog: false,
    searchseller: "",
    shops: [],
    singleExpand: false,
    dessertHeaders: [
      {
        text: "Product ID",
        align: "start",
        value: "id",
      },
      { text: "SKU", value: "ordered_date" },
      { text: "Name ", value: "price_total" },
      { text: "Color", value: "order_status" },
      { text: "Size", value: "adminaction", sortable: false },
      { text: "View", value: "actions", sortable: false },
      // { text: 'test', value: 'tes' },
    ],
    shopHeaders: [
      {
        text: "Shop Name",
        align: "start",
        value: "shop_name",
      },
      { text: "Location", value: "shop_location" },
      // { text: "Name ", value: "price_total" },
      // { text: "Color", value: "order_status" },
      // { text: "Size", value: "adminaction", sortable: false },
      { text: "View", value: "actions", sortable: false },
      // { text: 'test', value: 'tes' },
    ],
    WarehouseHeaders: [
      {
        text: "Warehouse Name",
        align: "start",
        value: "warehouse_name",
      },
      { text: "Warehouse Location", value: "warehouse_location" },
      // { text: "Name ", value: "price_total" },
      // { text: "Color", value: "order_status" },
      // { text: "Size", value: "adminaction", sortable: false },
      { text: "View", value: "actions", sortable: false },
    ],

    showItem: {},

    tickets: [],

    contact: {},
    Logged_user: {},
    orders: [],

    recentOrders: [],
    tempticket: [],
    pendingarray: [],
    Cancelledarray: [],
    show: false,
    today_order: 0,
    total_customer: 0,
    total_seller: 0,
    total_staff: 0,
    admin_status: "",
    sellerPdetails: {},
    seriesob: {},
    pending: 0,
    cancelled: 0,
    delivered: 0,
    subticket: [],
    user: {},

    colorsslider: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],

    series: [],
    seriesdemo: [50, 20, 30],

    chartOptions: {
      chart: {
        width: 280,
        type: "pie",
        offsetX: 60,
      },
      labels: ["Delivered", "Pending", "Cancelled"],
      colors: ["#4CAF50", "#EFCE62", "#F94B4B"],
      responsive: [
        {
          breakpoint: 1500,
          options: {
            chart: {
              width: "100%",
              height: "250px",
              offsetX: 0,
            },
            legend: {
              // position: "bottom",
              show: false,
            },
          },
        },

        {
          breakpoint: 1700,
          options: {
            chart: {
              width: "350px",
              height: "250px",
              offsetX: 0,
            },
            legend: {
              // position: "top",
              show: true,
            },
          },
        },
      ],

      legend: {
        position: "left",
        offsetY: 25,
        offsetX: -30,
        height: 630,
        itemMargin: {
          vertical: 20,
        },
      },
    },
  }),

  methods: {
    initialize() {
      axios.get(`Cart/orders_pending/`).then((response) => {
        if (response.data.success) {
          this.recentOrders = response.data.data;
          // console.log("order", this.recentOrders);
        }
        this.loading = false;
      });

      axios
        .get(`manager/unattend_products/${this.Logged_user.user_id}`)
        .then((response) => {
          if (response.data.success) {
            this.warehouses = response.data.ware_house;
            this.loading = false;
            this.shops = response.data.shop;
          }
        });

      axios.get("supports/dashboard/").then((response) => {
        this.today_order = response.data.data.orders;
        this.total_customer = response.data.data.total_customers;
        this.total_seller = response.data.data.total_sellers;
        this.total_staff = response.data.data.total_staff;
      });
    },
    getdeliverychirt() {
      axios.get("supports/delivery_statistics/").then((response) => {
        if (response.data.success) {
          this.seriesob = response.data;
          this.delivered = response.data.delivered;
          this.pending = response.data.pending;
          this.cancelled = response.data.cancelled;
          this.series[0] = this.delivered;
          this.series[1] = this.pending;
          this.series[2] = this.cancelled;
          // this.pendingarray=response.data.pending
          // this.Cancelledarray=response.data.cancelled
        }
      });
    },
    sellerproductdetail() {
      axios
        .get(`product/get_product_info/${this.orders[0].id}`)
        .then((response) => {
          this.sellerPdetails = response.data.data;
        });
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },

    viewDetail(item) {
      // console.log("this is for item array", item);
      this.sellerPdetails = item;
      this.dialog = true;
    },
    getproduct() {
      axios.get("product/display/5/").then((response) => {
        //this.products = response.data.data;

        // console.log("this is temp", this.subticket);
      });
    },

    getreply() {
      axios.get("supports/ticket_list/").then((response) => {
        //this.tickets = response.data.data;
        this.tempticket = response.data.data;
        this.tickets = this.tempticket.slice(0, 3);
        // console.log("tickets", this.tickets);
      });
    },
    openchat(item) {
      this.$router.push("/dashboard/pages/supports");
    },

    closedialog() {
      this.dialog = false;
    },
    viewDetails(item) {
      this.$router.push({
        path: "/managerdashboard/pages/shop_tranfer",
        query: { id: item.id },
      });
    },
    viewDetailsWarehouse(item) {
      this.$router.push({
        path: "/managerdashboard/pages/warehouseproduct_tranfer",
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.Logged_user = JSON.parse(localStorage.getItem("user"));

    this.user = JSON.parse(localStorage.getItem("user"));
    this.initialize();
    this.getdeliverychirt();
    this.getproduct();
    this.getreply();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>